import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import Grid from '../components/grid/grid';
import GridCell from '../components/grid/gridCell';
import PanoramaDefault from '../components/parts/panoramas/panoramaDefault';
import Intro from '../components/parts/content/intro';
import CardDefault from '../components/parts/cards/cardDefault';
import SeoBlock from '../components/parts/content/seo';

const IndexPage = ({ data }) => {
    const items = [];
    const prismicData = data.prismic.articles;
    const { allArticles } = data.prismic;

    const panorama = {
        title: prismicData.panorama_title[0].text,
        text: prismicData.panorama_text[0].text,
        image: {
            src: prismicData.panorama_imageSharp.childImageSharp.fluid,
            alt: prismicData.panorama_image.alt
        }
    };

    const intro = {
        title: prismicData.intro_title?.[0].text,
        text: prismicData.intro_text
    };

    const seo = {
        title: prismicData.seo_title[0].text,
        text: prismicData.seo_text
    };

    for (const [index, { node }] of allArticles.edges.entries()) {
        items.push(
            <GridCell cellSizes={{ small: '100%', medium: '50%', large: `${(1 / 3) * 100}%` }} key={index}>
                <CardDefault
                    content={{
                        image: node.archive_imageSharp.childImageSharp.fluid,
                        publicationDate: node.publication_date,
                        title: node.title[0].text,
                        description: node.description[0].text,
                        link: `nieuws/${node._meta.uid}`,
                        label: node.label
                    }}
                >
                    {index}
                </CardDefault>
            </GridCell>
        );
    }

    return (
        <Layout>
            <SEO title={panorama.title} />
            <PanoramaDefault
                background={false}
                alignment="left"
                content={{
                    image: panorama.image,
                    title: panorama.title,
                    text: panorama.text
                }}
            />
            <Contain>
                <Intro intro={intro} />
                <SCards>
                    <Grid gutter="normal">{items}</Grid>
                </SCards>
                <SeoBlock content={seo} />
            </Contain>
        </Layout>
    );
};

const SCards = styled.div`
    margin-top: 40px;
    margin-bottom: 100px;
`;

export default IndexPage;

export const pageQuery = graphql`
    query {
        prismic {
            allArticles(sortBy: publication_date_DESC) {
                edges {
                    node {
                        publication_date
                        title
                        description
                        label
                        archive_image
                        _meta {
                            uid
                        }
                        archive_imageSharp {
                            childImageSharp {
                                fluid(maxWidth: 700, maxHeight: 500, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
            articles(lang: "nl-nl", uid: "nieuws") {
                panorama_image
                panorama_imageSharp {
                    childImageSharp {
                        fluid(maxWidth: 2560, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                panorama_text
                panorama_title
                intro_title
                intro_text
                seo_title
                seo_text
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.object
};
